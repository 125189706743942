import React, { useEffect, useState } from "react"
import { FacebookCircle, Twitter, Mail, Link } from "../../components/Icon"
import ScrollAnimation from "react-animate-on-scroll"
import { message } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"
import "./BlogMasthead.less"

export default function BlogMasthead(props) {
  const { t } = useI18next()
  const [currentUrl, setCurrentUrl] = useState("")
  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])
  return (
    <div className="blogmasthead">
      <figure className="blogmasthead-media">
        <img className="blogmasthead-image" src={props.image} alt=""></img>
        <figcaption className="blogmasthed-imagecaption">
          {props.imageCaption}
        </figcaption>
      </figure>

      <div className="blogmasthead-content">
        <div className="detail">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <p style={{ marginBottom: 0, fontWeight: "600" }}>
              <span className="category">{props.category}</span> | {props.date}
            </p>
            <h1>{props.title}</h1>
          </ScrollAnimation>

          <div className="social-share">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce={true}
              delay={300}
            >
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: 20 }}
                aria-label="Bagikan ke Facebook"
              >
                <FacebookCircle />
              </a>
              <a
                href={`https://twitter.com/intent/tweet/?text=&url=${currentUrl}`}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: 20 }}
                aria-label="Bagikan ke X"
              >
                <Twitter width="24px" height="24px" />
              </a>
              <a
                href={`mailto:?Subject=${props.title}&body=${currentUrl}`}
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: 20 }}
                aria-label="Kirim email"
              >
                <Mail />
              </a>
              <div style={{ display: "inline-block" }}>
                <Link
                  target="_blank"
                  onClick={() => {
                    window.navigator.clipboard.writeText(currentUrl)
                    message.success("Copied to clipboard")
                  }}
                  rel="noreferrer"
                />
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="meta">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div style={{ marginBottom: 20 }}>
              <p>
                <span className="label">{t("Penulis")}</span>
                <br />
                {props.author}
              </p>
            </div>
            <div style={{ marginBottom: 20 }}>
              <p>
                <span className="label">{t("Fotografer")}</span>
                <br />
                {props.photographer}
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  )
}
